<template>
  <div id="app">
    <router-view></router-view>
    <link rel="stylesheet" href="//at.alicdn.com/t/c/font_4267719_rjneitcce8.css" />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
  #app {
    height: 100vh;
  }

  html, body,#app {
    margin: 0;
    padding: 0;
  }
</style>
