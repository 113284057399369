import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import router from './router'
import store from './store'
import axios from 'axios'
import './mock/mock.js'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$axios = axios

// router.beforeEach((to, from, next) => {
//   if (to.path === '/login') return next()
//   const tokenStr = JSON.parse(window.sessionStorage.getItem('token'))
//   if (!tokenStr) { 
//     alert('请先登录账号再访问')
//     return next('/login')
//   }
//   next()
// })

Vue.filter('dateFormate', function(originVal) {
	const dt = new Date(originVal)
	
	const y = dt.getFullYear()
	const m = (dt.getMonth() + 1 + '').padStart(2, '0')
	const d = (dt.getDate() + '').padStart(2,'0')

	const hh = (dt.getHours() + '').padStart(2,'0')
	const mm = (dt.getMinutes() + '').padStart(2,'0')
	const ss = (dt.getSeconds() + '').padStart(2,'0')

	return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
	})

new Vue({
  router,
  store,
  axios,
  render: h => h(App),
}).$mount('#app')
