import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/frame',
        name: 'Frame',
        component: () => import('../components/Frame.vue'),
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import('../views/Home.vue')
            },
            {
                path: 'notice',
                name: 'Notice',
                component: () => import('../views/notice/notice.vue'),
            },
            {
                path: 'Activity',
                name: 'MyActivity',
                component: () => import("../views/Components/Activity/activity.vue")
            },
            {
                path: 'activityuser',
                name: 'MyActivityuser',
                component: () => import("../views/Components/Activity/activityuser.vue")
            },
            {
                path: 'activitydetails',
                name: 'ActivityDetails',
                component: () => import("../views/Components/Activity/activitydetails.vue")
            },
            {
                path: 'App/idea',
                name: 'MyIdea',
                component: () => import("../views/Components/APP/idea.vue"),
            },
            {
                path: 'App/pay',
                name: 'Pay',
                component: () => import("../views/Components/APP/pay.vue"),
            },
            {
                path: 'Gift',
                name: 'Gift',
                component: () => import("../views/Components/Gift/gift.vue")
            },
            {
                path: 'Person/person',
                name: 'Person',
                component: () => import("../views/Components/Person/person.vue")
            },
			{
			    path: 'Person/idcard',
			    name: 'Idcard',
			    component: () => import("../views/Components/Person/idcard.vue")
			},
            {
                path: 'Person/authentication',
                name: 'Authentication',
                component: () => import("../views/Components/Person/authentication.vue")
            },
            {
                path: 'Person/authenticationdetails',
                name: 'Authenticationdetails',
                component: () => import("../views/Components/Person/authenticationdetails.vue")
            },
            {
                path: 'Person/edudetails',
                name: 'Edudetails',
                component: () => import("../views/Components/Person/edudetails.vue")
            },
            {
                path: 'Person/education',
                name: 'Education',
                component: () => import("../views/Components/Person/education.vue")
            },
            {
                path: 'Daili/daili',
                name: 'Daili',
                component: () => import("../views/Components/Daili/daili.vue")
            },
            {
                path: 'Daili/dailijilu',
                name: 'DailiJilu',
                component: () => import("../views/Components/Daili/dailijilu.vue")
            },

            {
                path: 'Partnerships/partnertimoney',
                name: 'Partner',
                component: () => import("../views/Components/Partnerships/partnertimoney.vue")
            },
            {
                path: 'Vip/vip',
                name: 'Vip',
                component: () => import("../views/Components/Vip/vip.vue")
            },
            {
                path: 'Vip/VipUser',
                name: 'VipUser',
                component: () => import("../views/Components/Vip/VipUser.vue")
            },
            {
                path: 'Vip/deadline',
                name: 'Deadline',
                component: () => import("../views/Components/Vip/deadline.vue")
            },
            {
                path: 'Wchat',
                name: 'Wchat',
                component: () => import("../views/Components/Wchat/wchat.vue")
            },
            {
                path: 'Goods',
                name: 'Goods',
                component: () => import("../views/Components/Goods/goods.vue")
            },
            {
                path: 'Robot',
                name: 'Robot',
                component: () => import("../views/Components/Robot/robot.vue")
            },
            {
                path: 'goodsdetail',
                name: 'GoodsDetail',
                component: () => import("../views/Components/Goods/goodsdetail.vue")
            }, {
                path: 'Share/share',
                name: 'Share',
                component: () => import("../views/Components/Share/share.vue")
            },
            {
                path: 'function',
                name: 'Function',
                component: () => import("../views/Components/Function/function.vue")
            },
            {
                path: 'report',
                name: 'Report',
                component: () => import("../views/Components/Report/report.vue")
            },
            {
                path: 'reportuser',
                name: 'Reportuser',
                component: () => import("../views/Components/Report/reportuser.vue")
            },
            {
                path: 'shield',
                name: 'Shield',
                component: () => import("../views/Components/Shield/shield.vue")
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

//避免重复点击导航爆错 
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router